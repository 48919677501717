<template>
  <el-dialog
    title="Edit Reseller Information"
    :model="formData"
    :visible.sync="isVisible"
  >
    <el-form :rules="rules" :model="formData" ref="editResellerForm">
      <el-form-item label="Company Name" prop="company_name">
        <el-input v-model="formData.company_name"></el-input>
      </el-form-item>
      <el-form-item label="Domain Name" prop="domain">
        <el-input v-model="formData.domain"></el-input>
      </el-form-item>
      <el-form-item label="Rate" prop="rate">
        <el-input v-model="formData.rate"></el-input>
      </el-form-item>
      <el-form-item label="Sender ID" prop="sender_id">
        <el-input v-model="formData.sender_id"></el-input>
      </el-form-item>
      <el-form-item>
        <span class="dialog-footer">
          <el-button @click="$emit('update:visible', false)">Cancel</el-button>
          <el-button
            :loading="loading"
            type="danger"
            @click="submitForm('editResellerForm')"
          >
            Confirm</el-button
          >
        </span>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      editData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      isVisible: {
        get() {
          return this.visible;
        },
        set(val) {
          this.$emit("update:visible", val);
        },
      },
    },
    data() {
      return {
        loading: false,
        formData: {
          id: "",
          company_name: "",
          domain: "",
          rate: "",
          sender_id: "",
        },
        rules: {
          company_name: [
            {
              required: true,
              message: "Please Enter company name.",
              trigger: "blur",
            },
          ],
          domain: [
            {
              required: true,
              message: "Please Enter valid domain name.",
              trigger: "blur",
              pattern:
                "^[a-zA-Z0-9]+([\\-\\.]{1}[a-zA-Z0-9]+)*\\.[a-zA-Z]{2,6}$",
            },
          ],
          rate: [
            {
              required: true,
              message: "Please Enter valid rate.",
              trigger: "blur",
            },
          ],
          sender_id: [
            {
              required: true,
              message: "Please Enter valid sender id.",
              trigger: "blur",
            },
          ],
        },
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.$emit("update:visible", false);
            this.loading = true;
            this.$store
              .dispatch(
                "admin/reseller/editResellerConfiguration",
                this.formData
              )
              .then(() => {
                this.$emit("update:visible", false);
                this.$message({
                  message: "Reseller Configuration Updated Successfully",
                  type: "success",
                });
              })
              .catch(() => {
                this.$message({
                  message:
                    "There was an issue declining reseller. Try again later",
                  type: "error",
                });
              })
              .finally(() => {
                this.loading = false;
              });
            this.$refs[formName].resetFields();
          } else {
            return false;
          }
        });
      },
    },
    watch: {
      visible(val) {
        if (val) {
          this.formData.id = this.editData.id;
          this.formData.company_name = this.editData.company_name;
          this.formData.domain = this.editData.domain;
          this.formData.rate = this.editData.rate;
          this.formData.sender_id = this.editData.sender_id?.sender_id || "";
        }
      },
    },
  };
</script>

<style scoped>
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
