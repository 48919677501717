<template>
  <div class="main_card_body">
    <div class="row">
      <div class="col-md-12" v-if="loading">
        <div class="row mt-5 pt-5">
          <div class="col-md-12" v-loading="loading"></div>
        </div>
      </div>
      <div class="col-md-12 my-4" v-if="!loading">
        <div class="d-flex justify-content-end">
          <div class="form_contain">
            <el-input
              placeholder="search name"
              class="search_item"
              style="height: 40px !important"
              v-model="search"
            >
              <i
                slot="prefix"
                style="padding-to: 2em"
                class="el-input__icon el-icon-search"
              ></i>
            </el-input>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="!loading">
        <el-table
          :data="displayData"
          :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
          style="width: 100%"
          :empty-text="'No Data Available'"
          height="55vh"
        >
          <!-- <el-table-column
                            label="Logo"
                            >
                            <template slot-scope="scope">
                               <img :src="`${url()}/${scope.row.logo}`" alt="" />
                            </template>
                        </el-table-column> -->
          <el-table-column label="Company Name" prop="company_name">
          </el-table-column>
          <el-table-column label="Domain Name" prop="domain"> </el-table-column>
          <el-table-column label="Phone" prop="phone"> </el-table-column>
          <el-table-column label="Rate" prop="rate"> </el-table-column>
          <el-table-column label="Status">
            <template slot-scope="scope">
              <button
                class="status_text"
                :class="{
                  pending: scope.row.status == 'pending',
                  approved: scope.row.status == 'completed',
                  declined: scope.row.status == 'declined',
                }"
              >
                {{ scope.row.status }}
              </button>
            </template>
          </el-table-column>
          <el-table-column label="Updated At">
            <template slot-scope="scope">
              <p style="margin: 0">
                {{ moment(scope.row.created_at).format("ll") }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="250">
            <template slot-scope="scope">
              <div>
                <el-button
                  type="warning"
                  size="small"
                  @click="editResellerData(scope)"
                  v-if="
                    scope.row.status == 'pending' ||
                    scope.row.status == 'declined'
                  "
                  >Edit</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  @click="declineResellerData(scope)"
                  v-if="
                    scope.row.status == 'pending' ||
                    scope.row.status == 'completed'
                  "
                  >Decline</el-button
                >
                <el-button
                  type="success"
                  size="small"
                  @click="confirmAccount(scope)"
                  v-if="
                    scope.row.status == 'pending' ||
                    scope.row.status == 'declined'
                  "
                  >Confirm</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-md-12" style="text-align: center; margin-top: 2em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div
      class="modal fade"
      id="actionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="contact"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content d-flex align-items-center" v-if="success">
          <successMessage :message="success" />
          <button @click="closeSuccess" class="__close__success__modal">
            Close
          </button>
        </div>
        <div class="modal-content pb-3" v-if="!success">
          <div class="modal-header pt-4 pb-2">
            <h5 class="modal-title" id="exampleModalLabel">Confirm Request</h5>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="row m-3">
              <div class="col-md-12">
                <el-form>
                  <div class="form_input_container">
                    <el-form-item label="Rate" prop="rate">
                      <el-input
                        type="text"
                        placeholder="Enter Rate"
                        v-model="formData.rate"
                        autocomplete="false"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="row mx-3 justify-content-center mb-4">
              <div
                class="col-md-12 modal__footer__btns d-flex justify-content-between"
              >
                <button style="width: 123px" class="cancel" @click="hideModal">
                  Cancel
                </button>
                <el-button
                  style=""
                  type="primary"
                  :loading="loadinSubmitBtn"
                  id="cancelinput"
                  @click="submitAction"
                  >Confirm Request</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->
    <editReseller
      :visible="editResellerVisible"
      :editData="editData"
      @update:visible="editResellerVisible = false"
    ></editReseller>
    <declineReseller
      :visible="declineResellerVisible"
      :declineData="declineData"
      @update:visible="declineResellerVisible = false"
    ></declineReseller>
  </div>
</template>

<script>
  import moment from "moment";
  import $ from "jquery";
  import store from "@/state/store.js";
  import editReseller from "../modals/editReseller.vue";
  import declineReseller from "../modals/declineReseller.vue";
  import appConfig from "@/app.config";

  export default {
    components: {
      editReseller,
      declineReseller,
    },
    page: {
      title: "Resellers",
      meta: [{ name: "description", content: appConfig.description }],
    },
    name: "admin-resellers",
    data() {
      return {
        loading: false,
        loadinSubmitBtn: false,
        success: "",
        errors: "",
        payload: {
          type: "",
        },
        formData: {
          index: "",
          id: "",
          rate: "",
        },
        page: 1,
        pageSize: 20,
        total: 0,
        search: "",
        editResellerVisible: false,
        editData: {},
        declineResellerVisible: false,
        declineData: {},
      };
    },
    methods: {
      closeSuccess() {
        this.success = null;
        this.errors = null;
        $("#actionModal").modal("hide");
      },
      hideModal() {
        this.success = null;
        this.errors = null;
        $("#actionModal").modal("hide");
      },
      handleCurrentChange(val) {
        this.page = val;
      },
      url() {
        return process.env.VUE_APP_ENGINE_URL;
      },
      openActionModal() {
        let element = this.$refs.actionModal.$el;
        $(element).modal("show");
      },
      submitAction() {
        this.loadinSubmitBtn = true;
        return store
          .dispatch("admin/reseller/confirmReseller", this.formData)
          .then((message) => {
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loadinSubmitBtn = false));
      },
      confirmAccount(message) {
        this.formData.id = message.row.id;
        this.formData.rate = message.row.rate;
        this.formData.index = message.$index;

        $("#actionModal").modal("show");
      },
      editResellerData(data) {
        this.editData = data.row;
        this.editResellerVisible = true;
      },
      declineResellerData(data) {
        this.declineData = data.row;
        this.declineResellerVisible = true;
      },
    },
    computed: {
      tableData() {
        return store.getters["admin/reseller/resellers"];
      },
      searchingTableData() {
        if (!this.search) {
          this.total = this.tableData.length;
          return this.tableData;
        }
        this.page = 1;
        return this.tableData.filter(
          (data) =>
            data.name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.amount == this.search
        );
      },
      displayData() {
        this.total = this.searchingTableData.length;
        return this.searchingTableData.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
    },
    mounted() {
      this.loading = true;
      if (this.$route.path.includes("complete")) {
        store
          .dispatch("admin/reseller/getCompletedResellers")
          .finally(() => (this.loading = false));
        return;
      }
      if (this.$route.path.includes("pending")) {
        store
          .dispatch("admin/reseller/getPendingResellers")
          .finally(() => (this.loading = false));
        return;
      }
      if (this.$route.path.includes("declined")) {
        store
          .dispatch("admin/reseller/getDeclinedResellers")
          .finally(() => (this.loading = false));
        return;
      }
    },
  };
</script>
<style scoped>
  #enter__account {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #f7921c;
    background: transparent;
    outline: none;
    border: none;
  }
  .status_text {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    width: 80px;
    height: 27px;
    border-radius: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    outline: none;
    border: none;
  }
  .pending {
    color: #f7921c;
    background: rgba(87, 87, 97, 0.1);
  }
  .approved {
    color: #36d962;
    background: rgba(54, 217, 98, 0.1);
  }

  .declined {
    color: #ff0000;
    background: rgba(255, 0, 0, 0.1);
  }
</style>
